import React, { type FC } from 'react';
import { useQuery } from 'react-apollo';

import { ContentUnifiedQuery, ContentBlogUnifiedQuery } from '@confluence/content-unified-query';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { StickyBanner } from '@confluence/content-header';
import { useContentType } from '@confluence/page-context';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import {
	useShowConvertPageToFolder,
	BANNER_HEIGHT,
} from '@confluence/folder/entry-points/useShowConvertPageToFolder';
import {
	useViewPageDistinctionBannerStore,
	VIEW_PAGE_DISTINCTION_BANNER_HEIGHT,
} from '@confluence/live-pages-features';
import { HighlightActionsProviderForNav4 } from '@confluence/highlight-actions/entry-points/HighlightActionsProvider';

import { ContentScreenComponent } from './ContentScreenComponent';
import { ContentContainerComponent } from './ContentContainerComponent';

const EMPTY_CONTENT_SCREEN_STYLES = {};
const EMPTY_LOOK_AND_FEEL = {};

export const ContentContainer: FC<{
	contentId: string;
	fetchTheme?: boolean;
	children: React.ReactNode;
	isScreenContentView?: boolean;
	isContainerContentView?: boolean;
	spaceKey?: string;
	errorAttribution: string;
	contentScreenStyles?: any;
	stickyHeader?: React.ReactNode;
}> = ({
	contentId,
	fetchTheme = false,
	children,
	isScreenContentView = false,
	isContainerContentView = false,
	spaceKey,
	errorAttribution = Attribution.BACKBONE as string,
	contentScreenStyles = EMPTY_CONTENT_SCREEN_STYLES,
	stickyHeader = null,
}) => {
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);

	const isLivePage = useIsLivePage();
	const [contentType] = useContentType();

	const { showBanner } = useShowConvertPageToFolder({ contentId, spaceKey: spaceKey || '' });
	const [{ isViewPageBannerVisible }] = useViewPageDistinctionBannerStore();

	let chooseContentUnifiedQuery = ContentUnifiedQuery;
	if (contentType === 'blogpost') {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	const queryResult = useQuery(chooseContentUnifiedQuery, {
		context: { single: true },
		skip: !fetchTheme || !contentId,
		variables: {
			contentId,
			spaceKey,
			versionOverride: null,
			includeAlias: isSpaceAliasFFEnabled,
			useNewContentTopper: isNewContentTopperFFEnabled,
		},
	});

	if (queryResult.error) {
		return (
			<ErrorBoundary attribution={errorAttribution}>
				{children}
				<ErrorDisplay error={queryResult.error} />
			</ErrorBoundary>
		);
	} else {
		const theme = Boolean(queryResult?.data?.space?.theme);
		const lookAndFeel = queryResult?.data?.space?.lookAndFeel || EMPTY_LOOK_AND_FEEL;

		return (
			<ErrorBoundary attribution={errorAttribution}>
				<ContentScreenComponent
					spaceKey={spaceKey}
					isThemed={theme}
					isLoading={queryResult.loading}
					lookAndFeel={lookAndFeel}
					isContentView={isScreenContentView}
					contentScreenStyles={contentScreenStyles}
					isLivePage={isLivePage}
				>
					{!theme && stickyHeader && <StickyBanner>{stickyHeader}</StickyBanner>}
					<ContentContainerComponent
						isThemed={theme}
						lookAndFeel={lookAndFeel}
						isContentView={isContainerContentView}
						isLoading={queryResult.loading}
						isLivePage={isLivePage}
						additionalPaddingTop={
							(showBanner ? BANNER_HEIGHT : 0) +
							(isViewPageBannerVisible ? VIEW_PAGE_DISTINCTION_BANNER_HEIGHT : 0)
						}
					>
						{typeof children === 'function'
							? children({ isThemed: theme, isLoading: queryResult.loading })
							: children}
					</ContentContainerComponent>
					<HighlightActionsProviderForNav4 />
				</ContentScreenComponent>
			</ErrorBoundary>
		);
	}
};
