import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
	VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
	getExperienceTracker, // eslint-disable-line no-restricted-imports
} from '@confluence/experience-tracker';
import { WebItemLocation, SYSTEM_CONTENT_METADATA } from '@confluence/web-item-location';
import { WebItemList } from '@confluence/web-item-list';
import { CONTENT_METADATA_PAGE_RESTRICTIONS_ID } from '@confluence/web-item';

import { ConnectAppLoadEnd, getConnectAppsItems } from './cp-measure';
export class SystemContentMetadata extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string.isRequired,
		versionOverride: PropTypes.number,
		excludeIds: PropTypes.arrayOf(PropTypes.string),
	};

	static defaultProps = {
		excludeIds: [],
	};

	componentDidMount() {
		const { excludeIds, contentId } = this.props;
		if (!excludeIds.includes(CONTENT_METADATA_PAGE_RESTRICTIONS_ID)) {
			getExperienceTracker().start({
				id: contentId,
				name: VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
			});
		}
	}

	render() {
		const { contentId, versionOverride, excludeIds } = this.props;

		return (
			<WebItemLocation
				tagName="span"
				contentId={contentId}
				location={SYSTEM_CONTENT_METADATA}
				version={versionOverride}
			>
				{({ webItems }) => {
					let filteredWebItems = webItems;
					if (excludeIds && excludeIds.length) {
						filteredWebItems = filteredWebItems.filter((item) => !excludeIds.includes(item.id));
					}
					return (
						<>
							<WebItemList
								pageId={contentId}
								webItems={filteredWebItems}
								id="system-
                content-items"
							/>
							<ConnectAppLoadEnd
								location={SYSTEM_CONTENT_METADATA}
								items={getConnectAppsItems(filteredWebItems)}
							/>
						</>
					);
				}}
			</WebItemLocation>
		);
	}
}
