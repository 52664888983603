import { LoadableBackground } from '@confluence/loadable';

export const AutoConversionByLine = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AutoConversionByLine" */ '../src/AutoConversionByLine/AutoConversionByLineComponent'
			)
		).AutoConversionByLine,
	name: 'AutoConversionByLine',
});
