import gql from 'graphql-tag';

export const fabricMigrationPreviewMigratePageMutation = gql`
	mutation FabricMigrationPreviewMigratePageMutation($contentId: ID!, $contentHash: String!)
	@experimental {
		experimentalMigratePages(contentId: $contentId, contentHash: $contentHash) {
			status
		}
	}
`;
