import gql from 'graphql-tag';

export const ExternalByLinePLInfoQuery = gql`
	query ExternalByLinePLInfoQuery($publicLinkId: ID!) {
		publicLinkInformation(id: $publicLinkId) {
			id
			type
			history {
				createdDate
				createdBy {
					...externalByLinePublicLinkUserFragment
				}
				ownedBy {
					...externalByLinePublicLinkUserFragment
				}
				lastOwnedBy {
					...externalByLinePublicLinkUserFragment
				}
			}
			version {
				by {
					...externalByLinePublicLinkUserFragment
				}
				friendlyWhen
				number
				confRev
				syncRev
				contentTypeModified
			}
		}
	}

	fragment externalByLinePublicLinkUserFragment on PublicLinkPerson {
		accountId
		displayName
		type
	}
`;
