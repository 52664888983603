import { createStore, createHook } from 'react-sweet-state';

import { generateMetricData, generateInitialState } from './helper';
import type { MeasuredLocationLoadedType } from './constants';

// connectApps state is keeping track of each measurable connect apps.
// this state will be updated when the measurable connect apps completed load,
// and store the information of the loaded connect apps.
// When all the measurable connect apps are loaded, it will aggregate the information,
// which can be used in places which would trigger PageSegmentLoadEnd

// measuredLocations defined all measurable connect apps,
// and used as initial state of connectApps with 'isLoaded' false,
// when each of those connectApps loaded, it will update connectApps state with 'isLoaded' true,
// so we know all connect apps are loaded and ready to measure

type ConnectAppsStateType = {
	connectApps: MeasuredLocationLoadedType[];
};

type ConnectAppsResult = {
	[key: string]: string[];
};

type SelectorState = {
	isReady: boolean;
	data: ConnectAppsResult | null;
};

const actions = {
	updateConnectApps:
		(props: MeasuredLocationLoadedType) =>
		({ setState, getState }) => {
			const { location, items, isLoaded } = props;
			// Update connectApps when it completed load
			const newState: MeasuredLocationLoadedType[] = getState().connectApps.map(
				(data: MeasuredLocationLoadedType) => {
					if (data.location === location) {
						return {
							location,
							items,
							isLoaded,
						};
					}
					return data;
				},
			);
			setState({ connectApps: newState });
		},
	resetConnectApps:
		() =>
		({ setState }) => {
			setState({ connectApps: generateInitialState() });
		},
};

const Store = createStore<ConnectAppsStateType, typeof actions>({
	initialState: {
		connectApps: generateInitialState(),
	},
	actions,
	name: 'connectAppsState',
});

const getConnectAppsState = (state: {
	connectApps: MeasuredLocationLoadedType[];
}): SelectorState => {
	// Check if all measurable connect apps are loaded
	const isReady = state.connectApps.every((cp) => {
		return cp.isLoaded === true;
	});

	if (!isReady) {
		return {
			isReady: false,
			data: null,
		};
	}

	return {
		isReady: true,
		data: generateMetricData(state.connectApps),
	};
};

export const useConnectAppsState = createHook(Store, {
	selector: getConnectAppsState,
});
