import gql from 'graphql-tag';

export const ExternalByLineQuery = gql`
	query ExternalByLineQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			type
			history {
				createdDate
				createdBy {
					...externalByLineUserFragment
				}
				ownedBy {
					...externalByLineUserFragment
				}
				lastOwnedBy {
					...externalByLineUserFragment
				}
			}
			version {
				by {
					...externalByLineUserFragment
				}
				friendlyWhen
				number
				confRev
				syncRev
				contentTypeModified
			}
		}
	}

	fragment externalByLineUserFragment on Person {
		type
		displayName
		... on KnownUser {
			accountId
		}
		... on User {
			accountId
		}
	}
`;
