import React from 'react';

import { LoadableLazy } from '@confluence/loadable';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';

import { CommentButtonPlaceholder } from './CommentButtonPlaceholder';

// For SSR: If there is an SSR click, while the CommentButton component loads, a loading spinner
// will be shown until the component mounts
const SSR_ACTION_LOADING_SPINNER_STYLE = `padding: 0 1px;`;

export const CommentButtonLazy = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CommentButton" */ './CommentButton'))
			.CommentButton,
	loading: ({ isClickedInSSR, isDataLoaded }) =>
		isClickedInSSR ? (
			<SSRActionLoadingSpinner
				spinnerId="inline-comment-loading-spinner"
				actionType="inlineCommentButton"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={SSR_ACTION_LOADING_SPINNER_STYLE}
			/>
		) : (
			<CommentButtonPlaceholder isDisabled={!isDataLoaded} />
		),
});
