import {
	CONTENT_TITLE_SEGMENT_KEY,
	BYLINE_SEGMENT_KEY,
	CONNECT_APP_WEB_FRAGMENT_SEGMENT_KEY,
	createPageSegmentLoadMetric,
} from '@confluence/browser-metrics';

export const BYLINE_METRIC = createPageSegmentLoadMetric({
	key: BYLINE_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});

export const CONTENT_TITLE_METRIC = createPageSegmentLoadMetric({
	key: CONTENT_TITLE_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});

export const CONNECT_APP_WEB_FRAGMENT_METRIC = createPageSegmentLoadMetric({
	key: CONNECT_APP_WEB_FRAGMENT_SEGMENT_KEY,
	ssr: Boolean(window.__SSR_RENDERED__) ? { doneAsFmp: true } : undefined,
});
