import gql from 'graphql-tag';

import { contentAppearancePublishedFragment } from '@confluence/content-appearance/entry-points/fragments';

export const ContentTitleExternalQuery = gql`
	query ContentTitleExternalQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			title
			...contentAppearancePublishedFragment
			emojiTitlePublished: properties(key: "emoji-title-published") {
				nodes {
					id
					key
					value
					version {
						number
					}
				}
			}
			properties(key: "editor") {
				nodes {
					id
					key
					value
				}
			}
		}
	}

	${contentAppearancePublishedFragment}
`;
