import type { ComponentProps, FC, PropsWithChildren } from 'react';
import React, { Fragment, useContext, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { VIEW_PAGE_TITLE_EXPERIENCE, ExperienceStart } from '@confluence/experience-tracker';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { getTitleContentProperty } from '@confluence/custom-sites-extensions';
import {
	getHasEmojiPublished,
	getPublishedEmojiId,
} from '@confluence/emoji-title/entry-points/transformer';
import {
	getContentAppearancePublished,
	ContentAppearanceType,
} from '@confluence/content-appearance';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { ExternalShareContext } from '@confluence/external-share-context';
import { DocumentContentTitle } from '@confluence/document-title';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import {
	ReloadType,
	useQuickReloadSubscriber,
} from '@confluence/quick-reload/entry-points/subscription';
import { CUSTOM_SITES_PAGE_TITLE_FF } from '@confluence/emoji-title/entry-points/constants';

import { CONTENT_TITLE_METRIC } from '../perf.config';
import { ContentHeaderUnifiedQuery } from '../ContentHeaderUnifiedQuery.graphql';
import type {
	ContentHeaderUnifiedQuery as ContentHeaderUnifiedQueryT,
	ContentHeaderUnifiedQueryVariables,
	ContentHeaderUnifiedQuery_content_nodes,
} from '../__types__/ContentHeaderUnifiedQuery';
import { DEFAULT_CONTRIBUTORS_FETCH_LIMIT } from '../defaultQueryVariables';

import { ContentTitleExternalQuery } from './ContentTitleExternalQuery.graphql';
import { ContentTitleComponent } from './ContentTitleComponent';
import { ContentTitlePlaceholder } from './ContentTitlePlaceholder';
import type {
	ContentTitleExternalQuery as ContentTitleExternalQueryT,
	ContentTitleExternalQueryVariables,
} from './__types__/ContentTitleExternalQuery';
import type {
	ContentTitlePublicLinkInformationQuery as ContentTitlePublicLinkInformationQueryT,
	ContentTitlePublicLinkInformationQueryVariables,
} from './__types__/ContentTitlePublicLinkInformationQuery';
import { ContentTitlePublicLinkInformationQuery } from './ContentTitlePublicLinkInformationQuery.graphql';

const deriveLookAndFeelFromData = (data) => {
	const { space } = data;

	if (!space) {
		return null;
	}

	const { lookAndFeel } = space;

	return {
		...lookAndFeel,
		headings: lookAndFeel.headings.reduce((obj, heading) => {
			obj[heading.key] = heading.value;
			return obj;
		}, {}),
	};
};

type PassThroughProps = Pick<ComponentProps<typeof ContentTitleComponent>, 'headingContainer'>;

type ContentTitleProps = {
	contentId: string;
	spaceKey: string;
	banner?: React.ReactNode;
	prefix?: string;
	hasCoverPicture?: boolean;
	pageWidthType?: string;
	isEmbeddedPage?: boolean;
	isUsingPublicLinkInfo?: boolean;
};

type ContentQueryVariablesCacheBuster = (
	| ContentTitleExternalQueryVariables
	| ContentHeaderUnifiedQueryVariables
	| ContentTitlePublicLinkInformationQueryVariables
) & {
	cacheBuster?: string;
};

export const ContentTitle: FC<PropsWithChildren<ContentTitleProps & PassThroughProps>> = ({
	spaceKey,
	banner,
	contentId,
	children = null,
	prefix,
	hasCoverPicture,
	pageWidthType,
	isEmbeddedPage,
	...passThroughProps
}) => {
	return (
		<Fragment>
			<ExperienceStart id={contentId} name={VIEW_PAGE_TITLE_EXPERIENCE} />
			<ErrorBoundary attribution={Attribution.BACKBONE}>
				<PageSegmentLoadStart key={contentId} metric={CONTENT_TITLE_METRIC} />
				<ContentTitleWrapper
					contentId={contentId}
					spaceKey={spaceKey}
					banner={banner}
					prefix={prefix}
					hasCoverPicture={hasCoverPicture}
					pageWidthType={pageWidthType}
					isEmbeddedPage={isEmbeddedPage}
					{...passThroughProps}
				>
					{children}
				</ContentTitleWrapper>
			</ErrorBoundary>
		</Fragment>
	);
};

const ContentTitleWrapper: FC<PropsWithChildren<ContentTitleProps & PassThroughProps>> = ({
	spaceKey,
	banner,
	contentId,
	children,
	prefix,
	hasCoverPicture,
	pageWidthType,
	isEmbeddedPage,
	isUsingPublicLinkInfo,
	...passThroughProps
}) => {
	/**
	 * Position of a fabric page title is determined by the appearance of the content:
	 * full width content is left aligned
	 * default (fixed width) is center aligned
	 */
	const shouldCenterFabricPage = (graphqlData) => {
		const contentAppearance = getContentAppearancePublished(graphqlData);
		return contentAppearance.appearance === ContentAppearanceType.DEFAULT;
	};

	const isCenteredTitle = (graphqlData) => {
		// TinyMCE content page titles are not centred.
		// Fabric content page titles are centred by default; but should be left-aligned if full-width.
		// The new publicLinkInformation endpoint is returning 'v2' and not '"v2"
		const isFabricPage = ['"v2"', 'v2'].includes(graphqlData?.properties?.nodes?.[0]?.value);
		return isFabricPage ? shouldCenterFabricPage(graphqlData) : false;
	};

	const { isExternalShareRequest } = useContext(ExternalShareContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isNewContentTopperFFEnabled = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);

	const externalContentTitleQuery = isUsingPublicLinkInfo
		? ContentTitlePublicLinkInformationQuery
		: ContentTitleExternalQuery;

	const {
		loading,
		error,
		data: queryData,
		refetch,
	} = useQuery<
		| ContentTitlePublicLinkInformationQueryT
		| ContentTitleExternalQueryT
		| ContentHeaderUnifiedQueryT,
		ContentQueryVariablesCacheBuster
	>(isExternalShareRequest ? externalContentTitleQuery : ContentHeaderUnifiedQuery, {
		variables: {
			contentId,
			spaceKey,
			// Don't omit versionOverride, embeddedContentRender and limit
			// It's an Apollo bug that if preloader these needs to be match preloaded query
			// Also doesn't work if removing them from preloader variables HOT-109035
			versionOverride: null,
			embeddedContentRender: null,
			limit: DEFAULT_CONTRIBUTORS_FETCH_LIMIT,
			useNewContentTopper: isNewContentTopperFFEnabled,
			publicLinkId: isUsingPublicLinkInfo ? contentId : undefined,
		},
		context: {
			single: true,
			allowOnExternalPage: true,
		},
	});

	const externalQueryData = isUsingPublicLinkInfo
		? (queryData as ContentTitlePublicLinkInformationQueryT)?.publicLinkInformation
		: (queryData as ContentTitleExternalQueryT)?.singleContent;

	const data = isExternalShareRequest
		? externalQueryData
		: (queryData as ContentHeaderUnifiedQueryT)?.content?.nodes?.[0];

	const reload = useCallback(
		// Forcing refetch by passing dummy variable. Known Apollo bug (ノಠ益ಠ)ノ彡┻━┻, see page below:
		// https://pug.jira-dev.com/wiki/spaces/~683304838/pages/5971970200/Apollo+Client+-+weird+refetch+behavior
		// Reason using static cacheBuster: "":
		// ByLine component also makes ContentHeaderUnifiedQuery and refetch.
		// If using different cache buster values, the refetch in each component will make its owen refetch network call.
		// So setting a static value for this and ByLine component to make refetch network call only once.
		// And a static cache buster value can also force refetch to ignore cache of original query.
		() => refetch({ contentId, cacheBuster: '' }),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[refetch, contentId],
	);

	useQuickReloadSubscriber({
		name: 'title',
		types: [ReloadType.content],
		reload,
	});

	const title = data?.title || '';

	useEffect(() => {
		// Make sure that the GraphQL query has completed before firing any analytics.
		if (!loading && !error && title === '') {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					actionSubject: 'emptyPageTitle',
					action: 'shown',
					source: 'ContentTitleComponent',
					attributes: {
						contentId,
					},
				},
			}).fire();
		}
	}, [createAnalyticsEvent, title, loading, error, contentId]);

	// We don't check loading state here because it is ok to display staled data
	if (error) {
		// Displaying errors for content is handled other parts of the overall experience; the title doesn't need its own error state
		return <ErrorDisplay error={error} />;
	} else if (!data) {
		return <ContentTitlePlaceholder />;
	}

	const lookAndFeel = deriveLookAndFeelFromData(queryData);

	// NOTE: external share request doesn't allow to retrieve space details
	const spaceName = !isExternalShareRequest
		? (data as ContentHeaderUnifiedQuery_content_nodes).space?.name
		: '';

	const centerTitle = isCenteredTitle(data);

	// @ts-expect-error TODO FIXME type mismatch due to generics (most likely). Refactoring this to use `useQuery` hook should make the fix easier
	const emojiId = getPublishedEmojiId(queryData);

	// @ts-expect-error TODO FIXME some deep property null checks are failing, worth looking into
	const hasEmoji = getHasEmojiPublished(queryData);

	const { titleContentProperties } = getTitleContentProperty(queryData);

	return (
		<Fragment>
			<ContentTitleComponent
				contentId={contentId}
				title={title}
				lookAndFeel={lookAndFeel}
				banner={banner}
				centerTitle={centerTitle}
				prefix={prefix}
				emojiId={emojiId}
				hasEmoji={hasEmoji}
				loading={loading}
				hasCoverPicture={hasCoverPicture}
				isPageContentFullWidth={pageWidthType === `"full-width"`}
				isEmbeddedPage={isEmbeddedPage}
				spaceKey={spaceKey}
				contentType={(data as ContentHeaderUnifiedQuery_content_nodes)?.type ?? ''}
				titleContentProperties={titleContentProperties}
				isCustomSitesPageTitleFFOn={isNewContentTopperFFEnabled}
				{...passThroughProps}
			>
				{children}
			</ContentTitleComponent>
			<DocumentContentTitle
				contentTitle={title}
				contentSpace={spaceName || ''}
				titlePrefix={prefix}
			/>
		</Fragment>
	);
};

ContentTitle.propTypes = {
	spaceKey: PropTypes.string.isRequired,
	banner: PropTypes.node,
	contentId: PropTypes.string.isRequired,
	children: PropTypes.node,
	prefix: PropTypes.string,
	headingContainer: PropTypes.elementType,
};
