import type React from 'react';
import { useEffect } from 'react';

import { addExplicitScrollListener } from './scrollToElement';

export type DocumentScrollListenerProps = {
	/**
	 * This callback will be invoked right before a developer-generated scroll event
	 * is about to happen.
	 */
	onBeforeExplicitScroll(): void;
};

/**
 * Lets you listen to `DocumentScroller`-generated scroll events.
 * This is useful in situations when you want to distinguish between user-generated
 * scroll events, and developer-generated scroll events.
 *
 * @param options -- see `DocumentScrollListenerProps` docs
 */
export function useDocumentScrollListener(options: DocumentScrollListenerProps): void {
	const { onBeforeExplicitScroll } = options;
	useEffect(() => {
		const removeListener = addExplicitScrollListener(onBeforeExplicitScroll);

		return () => removeListener();
	}, [onBeforeExplicitScroll]);
}

/**
 * Refer to `useDocumentScrollListener` docs.
 */
export const DocumentScrollListener: React.FC<DocumentScrollListenerProps> = ({
	onBeforeExplicitScroll,
}) => {
	useDocumentScrollListener({ onBeforeExplicitScroll });

	return null;
};
DocumentScrollListener.displayName = 'DocumentScrollListener';
