import { expValEquals } from '@confluence/feature-experiments';

export enum NewRendererBylineCohorts {
	CONTROL = 'control',
	TEST = 'test',
}

// eslint-disable-next-line confluence-feature-gating/inline-usage
export const useIsNewRendererBylineExperiment = () =>
	expValEquals('cc-page-experiences-new-renderer-byline', 'cohort', 'test');
