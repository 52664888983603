import gql from 'graphql-tag';

import { ContentOperationsFragment } from '@confluence/entity-operations/entry-points/EntityOperationsFragment.fragment';

export const EditButtonQuery = gql`
	query EditButtonQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				...ContentOperationsFragment
				metadata {
					frontend {
						fabricEditorSupported
						embedded
						collabService
					}
				}
				subType
				type
				space {
					id
					key
				}
			}
		}
	}
	${ContentOperationsFragment}
`;
