import { getIsNav4Enabled, NAV_4_MAIN_CONTENT_ID } from '@confluence/nav4-enabled';

import { FABRIC_PAGE_CONTENT_ID } from './constants';
import { getClosestScrolledElementIndex } from './getClosestScrolledElementIndex';

export const rememberScrolledPosition = (contentId: string): void => {
	const isNav4Enabled = getIsNav4Enabled();

	// root page element
	const page: HTMLElement = isNav4Enabled
		? document?.getElementById(NAV_4_MAIN_CONTENT_ID) || document.documentElement
		: document.documentElement;

	// get fabric or classic renderer content id
	const pageContent: HTMLElement | null = document.getElementById(FABRIC_PAGE_CONTENT_ID);

	// if there's no page content, do nothing
	if (!pageContent) {
		return;
	}

	// if the user is already at the top of the page, then no need to scroll
	if (page.scrollTop / page.clientHeight < 0.25) {
		return;
	}

	// save closest scrolled element & contentId to localstorage
	getClosestScrolledElementIndex(page, pageContent, contentId);
};
