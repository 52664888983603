import {
	PAGE_METADATA_BANNER,
	SYSTEM_CONTENT_METADATA,
	ATL_PAGE_METADATA,
} from '@confluence/web-item-location';

export const measuredLocations = [PAGE_METADATA_BANNER, SYSTEM_CONTENT_METADATA, ATL_PAGE_METADATA];
export const MEASURE_KEY = 'com.atlassian.plugins.atlassian-connect-plugin';

export type MeasuredLocationLoadedType = {
	location: string;
	items: string[];
	isLoaded: boolean;
};
