import { getValue } from '@confluence/lifted-promise';
import { getSessionData } from '@confluence/session-data';

export function getLoginLink(continueUrl?: string | ((identity: string) => string)): string {
	const identity = getIdentity();

	if (typeof continueUrl === 'function') {
		continueUrl = continueUrl(identity);
	}
	if (!continueUrl) {
		continueUrl = window.location.href; // eslint-disable-line no-restricted-syntax
	}

	return `https://${identity}/login?application=confluence&continue=${encodeURIComponent(
		continueUrl,
	)}`;
}

function getIdentity() {
	let identity: string | undefined;
	const DEFAULT_PRODUCTION = 'id.atlassian.com';
	const DEFAULT_STAGING = 'id.stg.internal.atlassian.com';

	// 1. A branch build can be used in both staging and production instances so
	//    attempt to decide based on the frontend runtime environment rather than
	//    the frontend build environment:
	if (process.env.CLOUD_ENV === 'branch') {
		const { hostname } = window.location;
		if (hostname.endsWith('jira-dev.com')) {
			identity = DEFAULT_STAGING;
		} else if (hostname.endsWith('atlassian.net')) {
			identity = DEFAULT_PRODUCTION;
		}
	}

	// 2. Fall back to the frontend build environment:
	if (!identity) {
		identity = process.env.IDENTITY_PLATFORM;
	}

	// 3. Fall back to the backend runtime environment:
	if (!identity) {
		// Turning getLoginLink into a hook would add the complexity of skipability.
		// Given that (a) we're implementing a fallback here and (b) the frontend
		// app figures out whether login is necessary by looking at session data,
		// the backend runtime environment will very likely be known already.
		const environment = getValue(getSessionData())?.environment;
		identity = environment === 'PRODUCTION' ? DEFAULT_PRODUCTION : DEFAULT_STAGING;
	}

	return identity;
}
