import type { MeasuredLocationLoadedType } from './constants';
import { measuredLocations } from './constants';

export const getConnectAppsItems = (items: any[] | undefined, prop: string = 'completeKey') => {
	if (!items || !items.length) {
		return [];
	}
	return items.map((item) => {
		if (!item[prop]) {
			return null;
		}
		return item[prop];
	});
};

export const generateMetricData = (originalData: MeasuredLocationLoadedType[]) => {
	const isEmpty = originalData.every((cp: MeasuredLocationLoadedType) => {
		return cp.items.length === 0;
	});
	if (isEmpty) {
		return null;
	}
	return originalData.reduce((result, data) => {
		if (data.items?.length) {
			result[data.location] = data.items;
		}
		return result;
	}, {});
};

export const generateInitialState = () => {
	return measuredLocations.map(
		(location: string): MeasuredLocationLoadedType => ({
			location,
			items: [],
			isLoaded: false,
		}),
	);
};
