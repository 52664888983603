import { useEffect } from 'react';
import type { FC } from 'react';

import { measuredLocations, MEASURE_KEY } from './constants';
import { useConnectAppsState } from './useConnectAppsState';

type ConnectAppLoadEndProps = {
	location: string;
	items: string[];
};

export const ConnectAppLoadEnd: FC<ConnectAppLoadEndProps> = ({ location, items }) => {
	const [, connectAppsActions] = useConnectAppsState();

	useEffect(() => {
		if (location && measuredLocations.indexOf(location) !== -1) {
			const filteredItems = items.filter((item) => item.indexOf(MEASURE_KEY) !== -1);
			connectAppsActions.updateConnectApps({
				location,
				items: filteredItems,
				isLoaded: true,
			});
		}
	}, [location, items, connectAppsActions]);

	return null;
};
