import React, { useContext } from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import PropTypes from 'prop-types';

import { withAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	BannerContext,
	BannerStateContainer,
	FloatingBannerStateContainer,
} from '@confluence/banners';

import { StickyWrapper } from './StickyWrapper';

const bannerName = 'StickyBanner';

export class NotifyingStickyWrapper extends React.PureComponent {
	static propTypes = {
		...StickyWrapper.propTypes,
		bannerState: PropTypes.instanceOf(BannerStateContainer),
		bannerHeight: PropTypes.number,
		floatingBanners: PropTypes.instanceOf(FloatingBannerStateContainer),
	};

	componentWillUnmount() {
		const { floatingBanners } = this.props;
		floatingBanners.hideFloating(bannerName);
	}

	onStateChange = (newState) => {
		const { stickyStateChangeHandler, floatingBanners } = this.props;

		floatingBanners.showFloating(bannerName, newState.headerHeight);

		// pass state change down
		if (stickyStateChangeHandler) {
			stickyStateChangeHandler(newState);
		}
	};

	render() {
		const { children } = this.props;
		return (
			<StickyWrapper
				data-testid="sticky-wrapper"
				{...this.props}
				offsetHeight={this.props.bannerHeight}
				stickyStateChangeHandler={this.onStateChange}
			>
				{children}
			</StickyWrapper>
		);
	}
}

const WithStateContainers = (props) => {
	const { includeTopNav } = useContext(BannerContext);
	return (
		<Subscribe to={[BannerStateContainer, FloatingBannerStateContainer]}>
			{(bannerState, floatingBanners) => {
				return (
					<NotifyingStickyWrapper
						{...props}
						bannerHeight={
							bannerState.getTotalHeight(includeTopNav) -
							floatingBanners.getFloatingHeightBefore(bannerName)
						}
						bannerState={bannerState}
						floatingBanners={floatingBanners}
					/>
				);
			}}
		</Subscribe>
	);
};

export const StickyBanner = withAnalyticsEvents()(WithStateContainers);
