import React from 'react';

import { SplitButton } from '@atlaskit/button/new';

import { AISummarizeButtonPlaceholder } from '@atlassian/ai-summary';

import { fg } from '@confluence/feature-gating';
import { QuickSummaryButtonWhitePlaceholder } from '@confluence/quick-summary';

import { AIContentActionsMenuPopupPlaceholder } from './AIContentActionsMenuPopupPlaceholder';
import { WhiteSplitButtonWrapper } from './WhiteSplitButtonWrapper';

type AIContentActionsMenuSplitButtonPlaceholderProps = {
	summarizeText: string;
};

export const AIContentActionsMenuSplitButtonPlaceholder = ({
	summarizeText,
}: AIContentActionsMenuSplitButtonPlaceholderProps) => {
	return (
		<WhiteSplitButtonWrapper isWhiteButtonEnabled={fg('cc_smarts_white_summarize_button')}>
			<SplitButton>
				{fg('cc_smarts_white_summarize_button') ? (
					<QuickSummaryButtonWhitePlaceholder summarizeText={summarizeText} />
				) : (
					<AISummarizeButtonPlaceholder summarizeText={summarizeText} />
				)}
				<AIContentActionsMenuPopupPlaceholder
					shouldRenderWhite={fg('cc_smarts_white_summarize_button')}
				/>
			</SplitButton>
		</WhiteSplitButtonWrapper>
	);
};
