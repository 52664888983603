import { LoadableAfterPaint } from '@confluence/loadable';

type SendContentFeaturesProps = {
	contentId: string;
};

export const ReadTime = LoadableAfterPaint<SendContentFeaturesProps>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ReadTime" */ './ReadTime')).ReadTimeLoader,
});
