import gql from 'graphql-tag';

export const FabricEditorEligibilityQuery = gql`
	query FabricEditorEligibilityQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				metadata {
					frontend {
						fabricEditorEligibility
						contentHash
					}
				}
			}
		}
	}
`;
