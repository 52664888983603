import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { WebPanelLocation, WebPanel } from '@confluence/web-panel-location';
import { ATL_PAGE_METADATA } from '@confluence/web-item-location';
import { RequireLegacyWRM } from '@confluence/wrm';

import { ConnectAppLoadEnd, getConnectAppsItems } from './cp-measure';
const webPanelPageMetadataStyle = {
	verticalAlign: 'top',
	display: 'flex',
	alignItem: 'center',
	flexWrap: 'nowrap',
};

const WRM_MODULES = [
	/*
  https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-page-banner/browse
  still used for "page-metadata-banner-items"
  https://community.developer.atlassian.com/t/page-metadata-banner-in-custom-theme/12364
  */
	'wr!com.atlassian.confluence.plugins.confluence-page-banner:page-banner-common-resources',
];

export class PageMetadataAtl extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string.isRequired,
	};

	constructor(...args) {
		super(...args);
		this.state = {
			isPanelLoadedAndEmpty: false,
		};
	}

	handleQueryCompleted(data) {
		const isPanelLoadedAndEmpty = data && data.webPanels && data.webPanels.length === 0;
		this.setState({ isPanelLoadedAndEmpty });
	}

	render() {
		return (
			<>
				<WebPanelLocation
					contentId={this.props.contentId}
					location={ATL_PAGE_METADATA}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={webPanelPageMetadataStyle}
					onCompleted={(data) => this.handleQueryCompleted(data)}
				>
					{({ panels }) => {
						if (!panels || !panels.length) {
							return null;
						}

						return (
							<>
								<RequireLegacyWRM wrm={WRM_MODULES}>
									{() =>
										panels.map((panel) => <WebPanel key={panel.completeKey} html={panel.html} />)
									}
								</RequireLegacyWRM>
								<ConnectAppLoadEnd
									location={ATL_PAGE_METADATA}
									items={getConnectAppsItems(panels)}
								/>
							</>
						);
					}}
				</WebPanelLocation>
				{this.state.isPanelLoadedAndEmpty && (
					<ConnectAppLoadEnd location={ATL_PAGE_METADATA} items={getConnectAppsItems([])} />
				)}
			</>
		);
	}
}
