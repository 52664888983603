import { LoadableAfterPaint } from '@confluence/loadable';

export const lazyForgeUIExtensionsWithLoader = (loadingComponent) =>
	LoadableAfterPaint({
		loader: async () =>
			(await import(/* webpackChunkName: "loadable-main" */ './main')).ForgeUIExtensionsWrapper,
		loading: loadingComponent,
	});

export const LazyForgeUIExtensions = lazyForgeUIExtensionsWithLoader(undefined);
