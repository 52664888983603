import { LoadableAfterPaint } from '@confluence/loadable';

export const ConvertPageToFolderBannerLoader = LoadableAfterPaint<any>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ConvertPageToFolderBanner" */ './ConvertPageToFolderBanner'
			)
		).ConvertPageToFolderBanner,
});
