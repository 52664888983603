import { useQuery } from '@apollo/react-hooks';

import { CommentButtonQuery } from './CommentButtonQuery.graphql';

export const usePreloadCommentButton = (contentId, isCommentButtonSSRd) => {
	const { data, error } = useQuery(CommentButtonQuery, {
		fetchPolicy: isCommentButtonSSRd ? 'cache-first' : 'cache-and-network',
		variables: { pageId: contentId },
	});

	return { data, error };
};
