import type { ReactNode } from 'react';
import React, { Fragment, useEffect, useState, useContext, useCallback } from 'react';

import { EditorPreloadManager } from '@confluence/editor-preloader';
import { useEditSource } from '@confluence/load-edit-page/entry-points/useEditSource';

import { EditContentButtonPreloaderContext } from './EditContentButtonPreloaderContext';

export type EditContentButtonPreloaderProps = {
	contentId: string;
	spaceKey?: string;
	isEmbeddedEditor?: boolean;
	children: ReactNode;
	editSource: string;
};

export const EditContentButtonPreloader = ({
	contentId,
	spaceKey,
	children,
	editSource,
	isEmbeddedEditor,
}: EditContentButtonPreloaderProps) => {
	const preloader = useContext(EditContentButtonPreloaderContext); // See packages/confluence-fabric-editor/src/editorPreloader.tsx
	const [doPreload, setDoPreload] = useState(false);

	const [{ editSource: contextEditSource }, { setEditSource }] = useEditSource();

	// Cleanup editorPreloadManager on contentId change and unmount.
	useEffect(() => {
		return () => {
			if (doPreload) {
				EditorPreloadManager.removePreloadedId(contentId);
			}
		};
	}, [doPreload, contentId]);

	const onHover = useCallback(async () => {
		if (EditorPreloadManager.shouldPreload(contentId)) {
			setDoPreload(true);
			setEditSource(editSource);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, editSource]);

	return (
		<Fragment>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onMouseEnter={onHover}>{children}</div>
			{editSource === contextEditSource && doPreload && preloader
				? preloader({
						contentId,
						spaceKey,
						isEmbeddedEditor,
					})
				: null}
		</Fragment>
	);
};
