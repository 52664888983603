import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

const i18n = defineMessages({
	aiMenuAriaText: {
		id: 'ai-content-actions.popup-trigger.aria-text',
		defaultMessage: 'AI Actions Menu',
		description: 'Aria label for the AI actions menu which has no visible text',
	},
});

type AIContentActionsMenuPopupPlaceholderProps = {
	shouldRenderWhite?: boolean;
};

export const AIContentActionsMenuPopupPlaceholder = ({
	shouldRenderWhite = false,
}: AIContentActionsMenuPopupPlaceholderProps) => {
	const intl = useIntl();

	if (shouldRenderWhite) {
		return (
			<Button
				testId="ai-content-actions-menu-button-placeholder"
				appearance="subtle"
				isSelected={false}
				isDisabled
				iconAfter={<ChevronDownIcon label={intl.formatMessage(i18n.aiMenuAriaText)} />}
				label={intl.formatMessage(i18n.aiMenuAriaText)}
			/>
		);
	}

	return (
		<IconButton
			testId="ai-content-actions-menu-button-placeholder"
			appearance="subtle"
			isSelected={false}
			isDisabled
			icon={ChevronDownIcon}
			label={intl.formatMessage(i18n.aiMenuAriaText)}
		/>
	);
};
