import { confluenceLocalStorageInstance as localStorage, keys } from '@confluence/storage-manager';

import { RENDERED_CONTENT_SELECTOR } from './constants';

export const getClosestScrolledElementIndex = (
	page: HTMLElement,
	pageContent: HTMLElement | null,
	contentId: string,
): void => {
	if (!pageContent) {
		return;
	}
	// grab all of the direct children elements from the rendered content view
	const elementCollection: HTMLCollection | [] =
		pageContent.querySelector(RENDERED_CONTENT_SELECTOR)?.children || [];

	// set initial index to -1 in case no closest element is found
	let closestElementIndex: number = -1;

	// go through all direct children elements and see which is closest to the remembered scroll position
	if (elementCollection.length > 0) {
		const childrenElements = Array.from(elementCollection) as [HTMLElement];

		// calculate scroll position based off of how far a user has scrolled the page minus the distance from the renderer content to the top of the page
		const pageScrollTop: number = page.scrollTop - pageContent.offsetTop;

		childrenElements.reduce((best, elem, index) => {
			if (Math.abs(pageScrollTop - elem.offsetTop) < Math.abs(pageScrollTop - best.offsetTop)) {
				closestElementIndex = index;
				return elem;
			} else {
				return best;
			}
		});
	}

	// save the closest element's index to local storage
	localStorage.setItem(keys.LOCAL_STORAGE_CONTENT_VIEW_CLOSEST_ELEMENT_SCROLL_POSITION, {
		index: closestElementIndex,
		contentId,
	});
};
