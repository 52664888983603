import React from 'react';
import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	width: '1px',
	height: '120px',
});

// We can't export Placeholder directly because styled.div will render children
export const ContentTitlePlaceholder = () => <Placeholder />;
