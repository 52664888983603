import { createStore, createHook } from 'react-sweet-state';

type State = {
	isViewPageBannerVisible: boolean;
};

const initialState: State = {
	isViewPageBannerVisible: false,
};
export const actions = {
	setViewPageBannerVisibility:
		(visibility: boolean) =>
		({ setState }: { setState: any }) => {
			setState({ isViewPageBannerVisible: visibility });
		},
};

export const Store = createStore({
	initialState,
	actions,
	name: 'viewPageDistinctionBannerStore',
});

export const useViewPageDistinctionBannerStore = createHook(Store);
