import type { FC } from 'react';
import React from 'react';

import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { DocumentContentTitle } from '@confluence/document-title';
import { NotFound, OverviewNotFound } from '@confluence/not-found';

import { ContentExpansionContainer } from './styles';
import type { NotFoundContainerProps } from './types';

export const NotFoundContained: FC<NotFoundContainerProps> = ({ route, spaceKey }) => (
	<ContentExpansionContainer>
		<DocumentContentTitle notFound />
		{route === SPACE_OVERVIEW ? <OverviewNotFound spaceKey={spaceKey} /> : <NotFound />}
	</ContentExpansionContainer>
);
