import gql from 'graphql-tag';

/**
 * Request embedded property and if the content is not already embedded & NCS, then migrate the content to NCS (conditional on `confluence.collab.ncs.embedded.migrate` FF).
 * If migration is successful then embedded with be returned as true. If migration fails then the result will be false.
 */
export const EmbeddedWithMigrationQuery = gql`
	query EmbeddedWithMigrationQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				type
				metadata {
					frontend {
						embeddedWithMigration
					}
				}
			}
		}
	}
`;
