/**
 * Editor Preload Manager
 *
 * A static cache for preloaded contentId's designed to prevent
 * the duplication of preload triggers on any one page during
 * a user's session
 */
export class EditorPreloadManager {
	private static cache = new Set<string>();
	public static intentCache = new Set<string>();

	/**
	 * Should Preload
	 *
	 * A mutative lookup to the cache that'll insert the contentId provided
	 * if it's not already present. Returns true if the cache did not already
	 * contain the provided contentId
	 */
	public static shouldPreload(contentId: string) {
		if (this.cache.has(contentId)) {
			return false;
		}
		this.cache.add(contentId);
		return true;
	}

	/**
	 * Remove Preloaded ID
	 *
	 * Removes a contentId from the cache, allowing it to be re-preloaded
	 */
	public static removePreloadedId(contentId: string) {
		this.cache.delete(contentId);
		this.intentCache.delete(contentId);
	}

	/**
	 * Clear
	 *
	 * Removes all cached contentId's from the cache
	 */
	public static clear() {
		this.cache.clear();
		this.intentCache.clear();
	}
}
