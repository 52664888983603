import React from 'react';
import { useIntl } from 'react-intl-next';

import { LoadableAfterPaint } from '@confluence/loadable';
import { i18n } from '@confluence/quick-summary';

export { useDynamicSplitButton } from './useDynamicSplitButton';
export { CatchupContentType } from './__types__/CatchupLastViewedTimeQuery';
import { AIContentActionsMenuSplitButtonPlaceholder } from './AIContentActionsMenuSplitButtonPlaceholder';
export { BulkIssueCreate } from './bulk-issue-create/BulkIssueCreate';

const AIContentActionsMenuSplitButtonPlaceholderWithText = () => {
	const intl = useIntl();
	return (
		<AIContentActionsMenuSplitButtonPlaceholder
			summarizeText={intl.formatMessage(i18n.quickSummaryToolTipText)}
		/>
	);
};

export const AIContentActionsMenuSplitButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AIContentActionsMenuSplitButton" */ './AIContentActionsMenuSplitButton'
			)
		).AIContentActionsMenuSplitButton,
	loading: () => <AIContentActionsMenuSplitButtonPlaceholderWithText />,
});
