import { useLazyQuery } from 'react-apollo';
import { useMemo, useEffect, useCallback, useRef } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { expValEquals } from '@confluence/feature-experiments';

import type {
	FabricEditorEligibilityQueryVariables,
	FabricEditorEligibilityQuery as FabricEditorEligibilityQueryType,
} from './__types__/FabricEditorEligibilityQuery';
import { FabricEditorEligibilityQuery } from './FabricEditorEligibilityQuery.graphql';

/**
 * This hook is used to fetch the eligibility of the page for fabric editor migration.
 */
export const useFabricEligibilityMigrationQuery = ({
	contentId,
	isFabricSupported,
}: {
	contentId: string;
	isFabricSupported?: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const shouldFetch = useRef(
		isFabricSupported === false &&
			expValEquals('platform_editor_on_edit_convert', 'isEnabled', true),
	);

	const [getFabricEditorEligibilityQuery, { data, error, loading }] = useLazyQuery<
		FabricEditorEligibilityQueryType,
		FabricEditorEligibilityQueryVariables
	>(FabricEditorEligibilityQuery);

	const fireErrorEvent = useCallback(
		({ reason }: { reason: 'PROMISE_REJECTED' | 'APOLLO_ERROR' }) => {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'failed',
					actionSubject: 'fabricEditorEligibilityQuery',
					attributes: {
						contentId,
						reason,
					},
					source: 'editContentButton',
				},
			}).fire();
		},
		[contentId, createAnalyticsEvent],
	);

	useEffect(() => {
		if (shouldFetch.current) {
			getFabricEditorEligibilityQuery({
				variables: {
					contentId,
				},
			});
		}
	}, [contentId, isFabricSupported, getFabricEditorEligibilityQuery, fireErrorEvent]);

	if (error) {
		fireErrorEvent({ reason: 'APOLLO_ERROR' });
	}

	return useMemo(() => {
		if (!data && shouldFetch.current) {
			return { isFabricEligibilityLoading: true, fabricEditorEligibility: null, contentHash: null };
		}

		if (!data && loading) {
			return { isFabricEligibilityLoading: true, fabricEditorEligibility: null, contentHash: null };
		}

		if (data) {
			const metadataFrontend = data?.content?.nodes?.[0]?.metadata?.frontend;
			const fabricEditorEligibility = metadataFrontend?.fabricEditorEligibility;
			const contentHash = metadataFrontend?.contentHash;

			return { fabricEditorEligibility, contentHash, isFabricEligibilityLoading: false };
		}
		return { fabricEditorEligibility: null, contentHash: null, isFabricEligibilityLoading: false };
	}, [data, loading]);
};
