import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
	EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE,
	ExperienceStart,
	ExperienceSuccess,
} from '@confluence/experience-tracker';
import { RequireLegacyWRM } from '@confluence/wrm';
import { WebItemLocation, PAGE_METADATA_BANNER } from '@confluence/web-item-location';
import { CONTENT_METADATA_JIRA_ID } from '@confluence/web-item';
import { WebItemList } from '@confluence/web-item-list';

import { ConnectAppLoadEnd, getConnectAppsItems } from './cp-measure';
const WRM_MODULES = [
	'wrc!com.atlassian.confluence.plugins.confluence-feature-discovery-plugin',
	'wrc!com.atlassian.confluence.plugins.confluence-jira-metadata:confluence-jira-metadata-resources',
];

export class PageMetadataBanner extends PureComponent {
	static propTypes = {
		contentId: PropTypes.string.isRequired,
		versionOverride: PropTypes.number,
	};

	render() {
		const { contentId, versionOverride } = this.props;

		return (
			<>
				<ExperienceStart name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
				<WebItemLocation
					tagName="span"
					location={PAGE_METADATA_BANNER}
					contentId={contentId}
					version={versionOverride}
				>
					{({ webItems }) => {
						const items = (
							<>
								<WebItemList
									pageId={contentId}
									webItems={webItems}
									id="page-metadata-banner-items"
								/>
								<ExperienceSuccess name={EXTENSION_POINT_PAGE_METADATA_BANNER_EXPERIENCE} />
								<ConnectAppLoadEnd
									location={PAGE_METADATA_BANNER}
									items={getConnectAppsItems(webItems)}
								/>
							</>
						);
						if (webItems.some((item) => item.id === CONTENT_METADATA_JIRA_ID)) {
							return <RequireLegacyWRM wrm={WRM_MODULES}>{() => items}</RequireLegacyWRM>;
						}
						return items;
					}}
				</WebItemLocation>
			</>
		);
	}
}
