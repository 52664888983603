import gql from 'graphql-tag';

export const ContentTitlePublicLinkInformationQuery = gql`
	query ContentTitlePublicLinkInformationQuery($publicLinkId: ID!) {
		publicLinkInformation(id: $publicLinkId) {
			id
			title
			appearancePublished: properties(key: "content-appearance-published") {
				nodes {
					id
					key
					value
					version {
						number
					}
				}
			}
			emojiTitlePublished: properties(key: "emoji-title-published") {
				nodes {
					id
					key
					value
					version {
						number
					}
				}
			}
			properties(key: "editor") {
				nodes {
					id
					value
					key
				}
			}
		}
	}
`;
